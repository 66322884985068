.dashboard-page {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background: #f8fafc;
  height: 100vh;
}

dashboard-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

dashboard-logo {
  height: 48px;
  margin-right: 1rem;
}

dashboard-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

dashboard-content h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

dashboard-content p {
  font-size: 1.2rem;
  text-align: center;
} 