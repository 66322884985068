.not-found-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f0f9f0 0%, #e3f1e3 100%);
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.not-found-content {
  text-align: center;
  max-width: 600px;
  padding: 60px 40px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 10px 30px rgba(40, 167, 69, 0.08);
  animation: fadeIn 0.5s ease-out;
}

.not-found-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  opacity: 0.8;
}

.not-found-title {
  font-size: 120px;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(135deg, #28a745 0%, #20c997 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  margin-bottom: 20px;
}

.not-found-content h2 {
  font-size: 32px;
  margin: 20px 0;
  color: #212529;
  font-weight: 600;
}

.not-found-content p {
  font-size: 18px;
  color: #6c757d;
  margin-bottom: 40px;
  line-height: 1.6;
}

.home-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 14px 32px;
  background: linear-gradient(135deg, #28a745 0%, #20c997 100%);
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
}

.home-icon {
  width: 20px;
  height: 20px;
  filter: brightness(0) invert(1);
}

.home-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(40, 167, 69, 0.2);
  color: white;
  text-decoration: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 480px) {
  .not-found-title {
    font-size: 80px;
  }
  
  .not-found-icon {
    width: 60px;
    height: 60px;
  }
  
  .not-found-content h2 {
    font-size: 24px;
  }
  
  .not-found-content p {
    font-size: 16px;
  }
  
  .not-found-content {
    padding: 40px 20px;
  }
  
  .home-button {
    padding: 12px 24px;
    font-size: 14px;
  }
  
  .home-icon {
    width: 16px;
    height: 16px;
  }
} 