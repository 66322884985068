/* Import Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Modern, clean variables */
:root {
  --bg-primary: #f8f9fa;
  --bg-secondary: #f8f9fa;
  --text-primary: #1C1D20;
  --text-secondary: rgba(28, 29, 32, 0.7);
  --accent: #34A853;
  --accent-hover: #40c463;
  --spacing-unit: 8px;
  --font-family: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
}

.App {
  background-color: var(--bg-secondary);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(28, 29, 32, 0.06);
  z-index: 1000;
  height: 70px;
  display: flex;
  align-items: center;
}

.Header-content {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-logo {
  height: 28px;
  opacity: 1;
  transition: transform 0.3s ease;
}

.App-logo:hover {
  transform: scale(1.02);
}

.Header-nav {
  display: flex;
  align-items: center;
}

.Header-nav ul {
  display: flex;
  gap: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.Header-nav a {
  position: relative;
  text-decoration: none;
  color: inherit;
  padding: 5px 0;
}

.Header-nav a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--accent);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.Header-nav a.active::after {
  transform: scaleX(1);
}

.Header-nav a:hover::after {
  transform: scaleX(1);
}

/* Updated language selector */
.lang-select {
  background: transparent;
  border: none;
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
  margin: 0 20px;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%231C1D20' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 12px center;
  padding-right: 30px;
}

.lang-select:hover {
  background-color: rgba(52, 168, 83, 0.08);
}

/* Updated primary button */
.primary-btn {
  background: var(--accent);
  color: #FFFFFF;
  border-radius: 12px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01em;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(52, 168, 83, 0.1);
}

.primary-btn:hover {
  background: var(--accent-hover);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(52, 168, 83, 0.2);
}

.primary-btn:active {
  transform: translateY(0);
}

/* Pre-register button style */
.primary-btn.pre-register {
  background: transparent;
  color: var(--accent);
  border: 2px solid var(--accent);
}

.primary-btn.pre-register:hover {
  background: rgba(52, 168, 83, 0.1);
  color: var(--accent);
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(52, 168, 83, 0.1);
}

/* Add a scroll effect to header */
.header-scrolled {
  height: 60px;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 0 1px 3px rgba(28, 29, 32, 0.04);
}

/* Hamburger menu improvements */
.hamburger-menu {
  display: none;
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  margin-left: 20px;
}

.hamburger-menu span {
  display: block;
  width: 24px;
  height: 2px;
  background: var(--text-primary);
  margin: 4px 0;
  transition: all 0.3s ease;
}

.hero {
  padding-top: calc(var(--spacing-unit) * 12);
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(var(--spacing-unit) * 6);
  align-items: center;
}

.hero-text {
  text-align: left;
  padding: calc(var(--spacing-unit) * 4);
  animation: fadeIn 1s ease-out;
}

.hero-text h1 {
  font-size: 4rem;
  font-weight: 500;
  letter-spacing: -0.03em;
  background: linear-gradient(180deg, #1C1D20 0%, rgba(28, 29, 32, 0.8) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--font-family);
}

.Typing-message {
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: var(--text-secondary);
  opacity: 0.9;
  transition: opacity 0.3s ease;
  font-family: var(--font-family);
  min-height: 2.5em; /* Add fixed height to prevent layout shift */
  display: flex;
  align-items: center;
}

.Typing-message::after {
  content: '|';
  animation: blink 1s step-end infinite;
  margin-left: 2px;
}

@keyframes blink {
  from, to { opacity: 1; }
  50% { opacity: 0; }
}

.Typing-message:hover {
  opacity: 1;
}

.app-store-buttons {
  display: flex;
  gap: 12px;
  margin-top: 32px;
}

.store-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(52, 168, 83, 0.1);
  border: 1px solid rgba(52, 168, 83, 0.2);
  border-radius: 12px;
  padding: 12px 20px;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.store-btn:hover {
  background: rgba(52, 168, 83, 0.15);
  border-color: rgba(52, 168, 83, 0.3);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(52, 168, 83, 0.2);
}

.store-btn:active {
  transform: translateY(0);
}

.store-icon {
  width: 24px;
  height: 24px;
}

.store-icon svg {
  fill: currentColor;
}

.store-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.1;
}

.store-text small {
  font-size: 0.7rem;
  opacity: 0.8;
}

.app-preview {
  position: relative;
  padding: calc(var(--spacing-unit) * 4);
  padding-bottom: 140px;
  display: flex;
  justify-content: center;
  perspective: 1000px;
}

.phone-frame {
  position: relative;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
  border-radius: 55px;
  background: linear-gradient(145deg, #000000, #1a1a1a);
  padding: 12px;
  transform: rotateY(-25deg) rotateX(10deg) rotateZ(-2deg);
  transform-style: preserve-3d;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: 
    15px 15px 24px rgba(0, 0, 0, 0.2),
    8px 8px 15px rgba(0, 0, 0, 0.15),
    inset 0 0 5px rgba(255, 255, 255, 0.1);
  animation: float 6s ease-in-out infinite;
  perspective: 1000px;
}

.phone-frame:hover {
  transform: rotateY(-15deg) rotateX(5deg) rotateZ(-1deg) translateY(-10px);
  box-shadow: 
    20px 20px 30px rgba(0, 0, 0, 0.2),
    10px 10px 20px rgba(0, 0, 0, 0.15),
    inset 0 0 5px rgba(255, 255, 255, 0.15);
}

/* Remove the Dynamic Island */
.phone-frame::before {
  content: '';
  position: absolute;
  width: 4px;
  top: 40px;
  bottom: 40px;
  right: -4px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05) 50%,
    transparent
  );
  transform: translateZ(-2px);
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.phone-screen {
  position: relative;
  background: linear-gradient(145deg, #000000, #111111);
  border-radius: 45px;
  overflow: hidden;
  aspect-ratio: 9/19.5;
  box-shadow: 
    inset 0 0 10px rgba(255, 255, 255, 0.1),
    inset 0 0 0 2px rgba(255, 255, 255, 0.05);
  transform: translateZ(2px);
}

.carousel-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.app-screenshot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  filter: 
    brightness(1.1) 
    contrast(1.05)
    drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
  transform: translateZ(1px);
}

.app-screenshot.active {
  opacity: 1;
}

/* Side button styling */
.phone-frame .power-button,
.phone-frame .volume-up,
.phone-frame .volume-down {
  position: absolute;
  width: 4px;
  height: 40px;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  border-radius: 2px;
  transform-style: preserve-3d;
  transform: translateZ(1px);
  box-shadow: 
    -2px 0 4px rgba(0, 0, 0, 0.2),
    2px 0 4px rgba(0, 0, 0, 0.2);
}

.phone-frame .power-button {
  top: 130px;
  right: -3px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.phone-frame .volume-up {
  top: 100px;
  left: -3px;
  height: 45px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.phone-frame .volume-down {
  top: 160px;
  left: -3px;
  height: 45px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* Keep the reflective edges */
.phone-frame::after {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 55px;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.05) 30%,
    transparent 60%,
    rgba(255, 255, 255, 0.05) 100%
  );
  pointer-events: none;
  transform: translateZ(3px);
}

/* Add subtle shine effect */
@keyframes shine {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

/* Refined pulse animation for the Dynamic Island */
@keyframes pulse {
  0% {
    opacity: 0.98;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.98;
  }
}

/* Add depth to the screen */
.phone-screen::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.08),
    transparent 10%,
    transparent 90%,
    rgba(0, 0, 0, 0.15)
  );
  z-index: 1;
  pointer-events: none;
}

.App-footer {
  background: var(--bg-secondary);
  padding: 60px 24px 24px;
  margin-top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo {
  margin-bottom: 40px;
}

.footer-logo img {
  height: 32px;
}

.footer-video {
  max-width: 300px;
  width: auto;
  height: 100px;
  margin-top: 20px;
  display: block;
  background: white;
  border-radius: 12px;
  padding: 10px;
  object-fit: contain;
}

.footer-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  margin-bottom: 60px;
}

.footer-section h4 {
  color: var(--text-primary);
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: var(--font-family);
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 12px;
}

.footer-section ul li a {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.95rem;
  transition: color 0.2s ease;
  font-family: var(--font-family);
  font-weight: 400;
}

.footer-section ul li a:hover {
  color: var(--accent);
}

.social-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.social-link {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.95rem;
  transition: all 0.2s ease;
  padding: 8px 0;
}

.social-link svg {
  width: 20px;
  height: 20px;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.social-link:hover {
  color: var(--accent);
  transform: translateX(4px);
}

.social-link:hover svg {
  color: var(--accent);
  transform: scale(1.1);
}

.footer-bottom {
  padding-top: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.copyright {
  color: var(--text-secondary);
  font-size: 0.9rem;
  margin: 0;
}

.legal-links {
  display: flex;
  gap: 24px;
}

.legal-links a {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.2s ease;
}

.legal-links a:hover {
  color: var(--accent);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .App-footer {
    padding: 40px 20px 20px;
  }

  .footer-links {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .footer-bottom {
    flex-direction: column;
    text-align: center;
    gap: 16px;
  }

  .legal-links {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .App-header {
    height: 60px;
  }

  .Header-content {
    padding: 0 16px;
  }

  .App-logo {
    height: 24px;
  }

  .Header-nav {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }
  
  .hero {
    grid-template-columns: 1fr;
    padding: calc(var(--spacing-unit) * 8) calc(var(--spacing-unit) * 2);
  }
  
  .hero-text {
    text-align: center;
    padding: calc(var(--spacing-unit) * 2);
  }
  
  .hero-text h1 {
    font-size: 2.5rem;
  }
  
  .Typing-message {
    font-size: 1.2rem;
  }
  
  .phone-frame {
    transform: rotateY(-15deg) rotateX(5deg) rotateZ(-1deg);
    max-width: 260px;
  }
  
  .phone-frame:hover {
    transform: rotateY(-10deg) rotateX(3deg) rotateZ(-0.5deg) translateY(-5px);
  }
}

/* Add some Apple-like animations */
.store-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(52, 168, 83, 0.1);
  border: 1px solid rgba(52, 168, 83, 0.2);
  border-radius: 12px;
  padding: 12px 20px;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.store-btn:hover {
  background: rgba(52, 168, 83, 0.15);
  border-color: rgba(52, 168, 83, 0.3);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(52, 168, 83, 0.2);
}

.store-btn:active {
  transform: translateY(0);
}

/* Add smooth scroll behavior */
html {
  scroll-behavior: smooth;
}

/* Enhance the language selector */
.lang-select {
  background: rgba(52, 168, 83, 0.1);
  border: 1px solid rgba(52, 168, 83, 0.2);
  color: var(--text-secondary);
  font-size: 0.85rem;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 8px;
  transition: all 0.2s ease;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  appearance: none;
  -webkit-appearance: none;
}

.lang-select:hover {
  background: rgba(52, 168, 83, 0.15);
  border-color: rgba(52, 168, 83, 0.3);
}

/* Add subtle animation to the typing message */
.Typing-message {
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: var(--text-secondary);
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.Typing-message:hover {
  opacity: 1;
}

/* Enhance primary button */
.primary-btn {
  background: var(--accent);
  color: #FFFFFF;
  border-radius: 980px;
  padding: 8px 20px;
  font-size: 0.85rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  cursor: pointer;
}

.primary-btn:hover {
  background: var(--accent-hover);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(52, 168, 83, 0.4);
}

.primary-btn:active {
  transform: translateY(0);
}

/* Add these new animations */
@keyframes floatUp {
  0% { transform: translateY(40px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

@keyframes scaleIn {
  0% { transform: scale(0.9); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

/* Add animation classes */
.animate-float-up {
  animation: floatUp 0.8s ease-out forwards;
}

.animate-scale-in {
  animation: scaleIn 0.6s ease-out forwards;
}

.animate-fade-in {
  animation: fadeIn 0.8s ease-out forwards;
}

/* Add scroll-based parallax effect */
.parallax-section {
  position: relative;
  overflow: hidden;
}

.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  transform: translateY(var(--scroll-offset, 0));
  transition: transform 0.1s linear;
  z-index: -1;
}

/* Add smooth transitions */
.feature-item,
.stat-card,
.testimonial-card {
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Add hover effects */
.feature-item:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.stat-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 15px 30px rgba(52, 168, 83, 0.15);
}

/* Add gradient animations */
.gradient-text {
  background: linear-gradient(
    45deg,
    var(--accent) 0%,
    #40c463 50%,
    var(--accent) 100%
  );
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  animation: gradient 3s linear infinite;
}

@keyframes gradient {
  0% { background-position: 0% center; }
  100% { background-position: 200% center; }
}

/* Features Section */
.features-section {
  padding: 100px 24px;
  background: var(--bg-secondary);
}

.features-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 60px;
  background: linear-gradient(180deg, #1C1D20 0%, rgba(28, 29, 32, 0.8) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-grid {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding: 20px;
}

.feature-card {
  background: white;
  border-radius: 24px;
  padding: 40px 30px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: var(--accent);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 20px 40px rgba(52, 168, 83, 0.1);
}

.feature-card:hover::before {
  transform: scaleX(1);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 20px;
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: var(--text-primary);
}

.feature-card p {
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Stats Section */
.stats-section {
  padding: 80px 24px;
  background: white;
  position: relative;
  overflow: hidden;
}

.stats-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--accent),
    transparent
  );
}

.stats-grid {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
}

.stat-card {
  background: white;
  border-radius: 20px;
  padding: 40px 20px;
  text-align: center;
  box-shadow: 
    0 10px 30px rgba(0, 0, 0, 0.05),
    0 1px 3px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;
  position: relative;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 
    0 20px 40px rgba(52, 168, 83, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.05);
}

.stat-card h3 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--accent);
  margin-bottom: 10px;
  background: linear-gradient(135deg, var(--accent), #40c463);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-card p {
  font-size: 1.1rem;
  color: var(--text-secondary);
}

/* CTA Section */
.cta-section {
  padding: 100px 24px;
  background: linear-gradient(
    135deg,
    rgba(52, 168, 83, 0.05),
    rgba(52, 168, 83, 0.1)
  );
  text-align: center;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: var(--text-primary);
}

.cta-content p {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin-bottom: 40px;
}

.cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.secondary-btn {
  background: transparent;
  color: var(--text-primary);
  border: 1px solid var(--accent);
  border-radius: 980px;
  padding: 8px 20px;
  font-size: 0.85rem;
  font-weight: 400;
  letter-spacing: -0.01em;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
}

.secondary-btn:hover {
  background: rgba(52, 168, 83, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(52, 168, 83, 0.2);
}

.secondary-btn:active {
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .features-grid,
  .stats-grid {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 0 20px;
  }

  .features-section h2,
  .cta-content h2 {
    font-size: 2rem;
  }

  .feature-card,
  .stat-card {
    padding: 30px 20px;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .stat-card h3 {
    font-size: 2.5rem;
  }
}

/* Testimonials Section */
.testimonials-section {
  padding: 60px 24px;
  background: var(--bg-secondary);
}

.testimonials-section h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
  color: var(--text-primary);
}

.testimonials-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 0 20px;
}

.testimonial-card {
  background: white;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.testimonial-content {
  padding: 30px;
  position: relative;
}

.quote-icon {
  font-size: 3rem;
  color: var(--accent);
  opacity: 0.2;
  position: absolute;
  top: 20px;
  left: 20px;
}

.testimonial-text {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-secondary);
  margin: 20px 0;
  position: relative;
  z-index: 1;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
}

.author-avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--accent);
  display: flex;
  align-items: center;
  justify-content: center;
}

.author-avatar span {
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
}

.author-info h4 {
  font-size: 1rem;
  color: var(--text-primary);
  margin: 0;
}

.author-info p {
  font-size: 0.9rem;
  color: var(--text-secondary);
  margin: 4px 0 0 0;
}

@media (max-width: 768px) {
  .testimonials-section {
    padding: 40px 16px;
  }

  .testimonials-section h2 {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }

  .testimonials-container {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .testimonial-content {
    padding: 25px;
  }
}

/* Partners Section */
.partners-section {
  padding: 30px 0;
  background: var(--bg-primary);
  overflow: hidden;
}

.partners-section h2 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: var(--text-primary);
}

.partners-slider {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 10px 0;
}

.partners-track {
  display: flex;
  animation: slidePartners 25s linear infinite;
  gap: 80px;
  padding: 0 40px;
}

.partner-logo {
  width: 80px;
  height: auto;
  filter: grayscale(1);
  opacity: 0.5;
  transition: all 0.3s ease;
  flex-shrink: 0;
}

.partner-logo:hover {
  filter: grayscale(0);
  opacity: 1;
}

@keyframes slidePartners {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Pause animation on hover */
.partners-slider:hover .partners-track {
  animation-play-state: paused;
}

@media (max-width: 768px) {
  .partners-section {
    padding: 20px 0;
  }
  
  .partners-section h2 {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  
  .partner-logo {
    width: 60px;
  }
  
  .partners-track {
    gap: 60px;
  }
}

/* Features Showcase - Enhanced Layout */
.features-showcase {
  padding: 80px 0 100px;
  background: var(--bg-secondary);
  position: relative;
  overflow: hidden;
  margin-top: 0;
}

.features-showcase::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 0% 0%, rgba(52, 168, 83, 0.03) 0%, transparent 50%);
  pointer-events: none;
}

.features-showcase h2 {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 60px;
  color: #2d3436;
  position: relative;
}

.features-list {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}

.feature-row {
  display: flex;
  gap: 60px;
  padding: 30px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  transition: all 0.4s ease;
  position: relative;
}

.feature-row::before {
  content: '';
  position: absolute;
  left: -100px;
  right: -100px;
  top: 0;
  bottom: 0;
  background: transparent;
  transition: background 0.3s ease;
  z-index: -1;
  border-radius: 20px;
}

.feature-row:hover::before {
  background: rgba(52, 168, 83, 0.02);
}

.feature-row:hover {
  transform: translateX(20px);
  border-bottom-color: rgba(52, 168, 83, 0.2);
}

.feature-number {
  font-size: 3.5rem;
  font-weight: 700;
  color: var(--accent);
  opacity: 0.7;
  flex-shrink: 0;
  width: 100px;
  position: relative;
  line-height: 1;
}

.feature-number::after {
  content: '';
  position: absolute;
  right: -30px;
  top: 50%;
  width: 1px;
  height: 60%;
  background: rgba(52, 168, 83, 0.2);
  transform: translateY(-50%);
}

.feature-content {
  flex: 1;
  padding: 10px 0;
}

.feature-content h3 {
  font-size: 1.8rem;
  color: #2d3436;
  margin-bottom: 20px;
  font-weight: 600;
  position: relative;
}

.feature-content p {
  color: #636e72;
  line-height: 1.8;
  font-size: 1.1rem;
  margin: 0;
  max-width: 600px;
}

/* Enhanced responsive design */
@media (max-width: 1024px) {
  .features-showcase {
    padding: 100px 0;
  }

  .features-list {
    padding: 0 30px;
  }

  .feature-row {
    gap: 40px;
    padding: 40px 0;
  }

  .feature-number {
    font-size: 3rem;
    width: 80px;
  }

  .feature-content h3 {
    font-size: 1.6rem;
  }
}

@media (max-width: 768px) {
  .features-showcase {
    padding: 60px 0;
  }

  .features-showcase h2 {
    font-size: 2.2rem;
    margin-bottom: 40px;
  }

  .features-list {
    padding: 0 20px;
  }

  .feature-row {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
  }

  .feature-number {
    font-size: 2.5rem;
    width: auto;
  }

  .feature-number::after {
    display: none;
  }

  .feature-content h3 {
    font-size: 1.4rem;
    margin-bottom: 15px;
  }

  .feature-content p {
    font-size: 1rem;
    line-height: 1.6;
  }

  .feature-row:hover {
    transform: none;
  }

  .feature-row::before {
    left: -20px;
    right: -20px;
  }
}

/* Vision Section Styles */
.vision-section {
  padding: 80px 0;
  background: var(--bg-secondary);
  margin-top: 0;
}

.vision-section h2 {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 60px;
  color: #2d3436;
}

.vision-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.vision-item {
  background: white;
  padding: 40px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.vision-item:hover {
  transform: translateY(-5px);
}

.vision-icon {
  font-size: 3rem;
  margin-bottom: 20px;
}

.vision-item h3 {
  font-size: 1.5rem;
  color: #2d3436;
  margin-bottom: 20px;
}

.vision-item p {
  color: #636e72;
  line-height: 1.6;
  font-size: 1.1rem;
}

.vision-footer {
  text-align: center;
  max-width: 800px;
  margin: 60px auto 0;
  padding: 0 20px;
}

.vision-footer p {
  font-size: 1.2rem;
  color: #2d3436;
  line-height: 1.8;
  font-style: italic;
}

@media (max-width: 768px) {
  .vision-section {
    padding: 60px 0;
  }

  .vision-section h2 {
    font-size: 2rem;
    margin-bottom: 40px;
  }

  .vision-grid {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .vision-item {
    padding: 30px;
  }

  .vision-item h3 {
    font-size: 1.3rem;
  }

  .vision-item p {
    font-size: 1rem;
  }

  .vision-footer p {
    font-size: 1.1rem;
  }
}

/* FAQ Section Styles */
.faq-section {
  padding: 80px 0;
  background: var(--bg-secondary);
  margin-top: 0;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.faq-header {
  text-align: center;
  margin-bottom: 48px;
}

.faq-header h2 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 32px;
}

.faq-tabs {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 0 auto 40px;
  max-width: fit-content;
}

.faq-tab {
  padding: 12px 40px;
  border: none;
  background: #F5F5F5;
  color: #666;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 12px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.faq-tab.active {
  color: var(--text-primary);
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.faq-tab:hover:not(.active) {
  background: #EEEEEE;
}

.tab-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.faq-section-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
}

.section-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-icon svg {
  width: 24px;
  height: 24px;
  margin-top: -2px; /* Fine-tune vertical alignment */
}

.faq-content {
  margin-top: 40px;
}

.faq-item {
  background: white;
  border-radius: 12px;
  margin-bottom: 16px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.faq-question {
  width: 100%;
  padding: 20px 24px;
  background: white;
  border: none;
  text-align: left;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-primary);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
}

.faq-question:hover {
  color: var(--accent);
}

.faq-question.active {
  color: var(--accent);
}

.expand-icon {
  font-size: 1.5rem;
  color: var(--accent);
  transition: transform 0.3s ease;
}

.faq-question.active .expand-icon {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 0 24px 20px;
  color: var(--text-secondary);
  line-height: 1.6;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .faq-section {
    padding: 60px 16px;
  }

  .faq-header h2 {
    font-size: 2rem;
    margin-bottom: 24px;
  }

  .faq-tabs {
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    gap: 12px;
    padding: 8px 4px;
  }

  .faq-tab {
    padding: 12px 24px;
    min-width: auto;
    white-space: nowrap;
  }

  .faq-answer {
    padding: 0 20px 16px;
  }
}

/* Newsletter Section Styles */
.newsletter-section {
  padding: 80px 0;
  background: var(--bg-secondary);
  margin-top: 0;
}

.newsletter-wrapper {
  background: linear-gradient(135deg, var(--accent), #40c463);
  padding: 40px 20px;
  border-radius: 16px;
  margin: 40px auto;
  max-width: 1200px;
  color: white;
}

.newsletter-content {
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
}

.newsletter-icon {
  background: rgba(255, 255, 255, 0.2);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  color: white;
}

.newsletter-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: white;
}

.newsletter-subtitle {
  font-size: 1.1rem;
  opacity: 0.9;
  margin-bottom: 30px;
}

.newsletter-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.newsletter-form {
  margin-top: 2rem;
}

.input-group {
  display: flex;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

.input-group input {
  flex: 1;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.input-group input:focus {
  outline: none;
  border-color: var(--accent);
}

.input-group button {
  padding: 0.75rem 1.5rem;
  background: var(--accent);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
}

.input-group button:hover {
  background: var(--accent-hover);
}

@media (max-width: 768px) {
  .input-group {
    flex-direction: column;
  }
  
  .input-group button {
    width: 100%;
  }
}

.social-link {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.social-link:hover {
  color: var(--accent);
  transform: translateY(-1px);
}

.social-link svg {
  transition: all 0.2s ease;
}

.social-link:hover svg {
  transform: scale(1.1);
}

/* About Section Styles */
.about-section {
  padding: 80px 0;
  background: var(--bg-secondary);
  position: relative;
  overflow: hidden;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  align-items: center;
}

.about-content {
  text-align: left;
}

.about-content h2 {
  font-size: 2.5rem;
  color: var(--text-primary);
  margin-bottom: 24px;
  font-weight: 500;
}

.about-content p {
  font-size: 1.1rem;
  color: var(--text-secondary);
  line-height: 1.8;
  margin-bottom: 32px;
}

.about-image {
  position: relative;
  border-radius: 24px;
  overflow: hidden;
  padding: 20px;
  background: white;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

.about-image img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
  transition: transform 0.6s ease;
}

.about-image:hover img {
  transform: scale(1.03);
}

.about-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 40px;
}

.stat-item {
  text-align: center;
}

.stat-number {
  font-size: 2rem;
  font-weight: 500;
  color: var(--accent);
  margin-bottom: 8px;
}

.stat-label {
  font-size: 1rem;
  color: var(--text-secondary);
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .about-section {
    padding: 60px 20px;
  }

  .about-container {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .about-content {
    text-align: center;
  }

  .about-content h2 {
    font-size: 2rem;
  }

  .about-stats {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .stat-item {
    text-align: center;
  }
}

.section-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  position: relative;
}

.section-title {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 80px;
  color: var(--text-primary);
  position: relative;
}

.faq-grid {
  width: 100%;
}

/* Add responsive adjustments */
@media (max-width: 768px) {
  .section-container,
  .faq-container {
    padding: 0 20px;
  }
  
  .section-title {
    font-size: 2rem;
  }
}

/* General Section Layout */
.section-spacing {
  padding: 80px 0;
  position: relative;
}

/* Section Background Effects */
.section-bg-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.5;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .section-spacing {
    padding: 100px 0;
  }

  .section-container {
    padding: 0 30px;
  }

  .section-title {
    font-size: 2.4rem;
    margin-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .section-spacing {
    padding: 60px 0;
  }

  .about-section,
  .features-showcase,
  .vision-section,
  .stats-section,
  .faq-section,
  .newsletter-section {
    padding: 60px 0;
    margin-top: 0;  /* Remove overlap on mobile */
  }

  .section-container {
    padding: 0 20px;
  }

  .section-title {
    font-size: 2rem;
    margin-bottom: 40px;
  }
}

/* Add subtle transitions between sections */
.section-transition {
  position: relative;
  z-index: 1;
}

.section-transition::after {
  display: none; /* Remove the gradient transition */
}

.stats-divider {
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--accent),
    transparent
  );
  opacity: 0.3;
  margin: 40px 0;
  width: 100%;
  position: relative;
  padding: 0;
  background-color: var(--bg-secondary);
}

.stats-divider::after {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 3px;
  background: linear-gradient(
    90deg,
    transparent,
    var(--accent),
    transparent
  );
  filter: blur(2px);
  opacity: 0.5;
}

/* Update mobile responsiveness */
@media (max-width: 768px) {
  .app-preview {
    padding-top: 20px;
  }
}

.email-link {
  color: #4CAF50 !important;
}

.about-ad {
  margin-top: 40px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.about-ad:hover {
  transform: translateY(-5px);
}

.ad-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
}

@media (max-width: 768px) {
  .about-ad {
    margin-top: 30px;
  }
}

.nav-button {
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

@media (max-width: 768px) {
  .nav-button {
    width: 100%;
    text-align: left;
    padding: 12px 16px;
  }
}
