.admin-page {
  display: flex;
  height: 100vh;
  background: #f8fafc;
}

.admin-content {
  display: flex;
  width: 100%;
}

.admin-left {
  width: 500px;
  padding: 4rem;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.admin-header {
  margin-bottom: 3.5rem;
}

.admin-logo {
  height: 48px;
  margin-bottom: 2rem;
}

.admin-header h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.admin-functions {
  margin-top: 2rem;
}

.admin-functions h2 {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.admin-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #34A853 0%, #2d9147 100%);
  position: relative;
}

.admin-right-content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
  padding: 2rem;
  max-width: 500px;
  margin: 0 auto;
}

.admin-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../assets/greenbackground.png');
  background-size: cover;
  background-position: center;
  opacity: 0.1;
} 