.tracking-page {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Ensure it takes full height */
  padding: 0; /* Remove padding to center the box */
  background-color: #f0f4f8; /* Softer background color */
  background-image: url('../assets/greenbackground.png'); /* Set background image */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  overflow-y: auto; /* Allow vertical scrolling if needed */
  animation: backgroundFadeIn 2s ease-in-out forwards; /* Apply fade-in effect */
  transition: background-size 0.3s ease; /* Smooth transition for zoom effect */
}

.tracking-page.zoomed {
  background-size: 110%; /* Slightly zoom in */
}

.tracking-page-container {
  position: relative; /* Ensure the container is positioned relative */
  max-width: 1000px;
  margin: 20px auto; /* Add margin for spacing from top */
  padding: 20px; /* Increased padding for better spacing */
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(240, 240, 240, 0.9)); /* Subtle gradient background */
  border: 1px solid #e0e0e0; /* Light border */
  border-radius: 12px; /* Slightly less rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Softer shadow */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
  animation: fadeIn 0.1s ease-in-out; /* Fade-in effect for the container */
}

.tracking-page h1 {
  text-align: center;
  color: #2c3e50; /* Darker title color */
  margin-bottom: 16px; /* Reduced margin */
  font-size: 1.25rem; /* Smaller title size */
  font-weight: 200; /* Adjust font weight if needed */
}

.tracking-subtitle {
  text-align: center;
  color: #666;
  margin-bottom: 16px; /* Reduced margin */
  font-size: 1rem; /* Smaller subtitle size */
}

.tracking-search-container {
  max-width: 600px;
  margin: 0 auto 24px;
}

.tracking-search-form {
  display: flex;
  gap: 12px;
  margin-bottom: 20px; /* Increased margin for better spacing */
  width: 100%;
  justify-content: center;
}

.tracking-search-input {
  flex: 1;
  padding: 10px; /* Reduced padding */
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px; /* Smaller font size */
  transition: all 0.3s ease;
  background-image: url('../assets/barcode.png'); /* Example icon */
  background-repeat: no-repeat;
  background-position: 10px center;
  padding-left: 40px; /* Adjust padding for icon */
}

.tracking-search-input:focus {
  outline: none;
  border-color: #34A353;
  box-shadow: 0 0 0 3px rgba(52, 163, 83, 0.3); /* Softer shadow on focus */
}

.tracking-search-button {
  width: 100%; /* Make button full width */
  padding: 12px; /* Match padding with input field */
  background: linear-gradient(90deg, #28a745, #34A353);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px; /* Ensure font size matches input field */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
  white-space: nowrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tracking-search-button:hover {
  background: linear-gradient(90deg, #34A353, #28a745);
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.tracking-search-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.tracking-error {
  margin-top: 10px;
  color: #dc3545;
  text-align: center;
  font-size: 16px; /* Increased font size for better visibility */
  background-color: rgba(220, 53, 69, 0.1);
  border: 1px solid #dc3545;
  border-radius: 8px;
  padding: 12px; /* Increased padding */
  width: 100%; /* Ensure it takes full width */
}

.tracking-results-container {
  display: grid;
  gap: 0; /* Remove gap */
  margin-top: 20px; /* Increased margin for better spacing */
  width: 100%;
  align-items: center;
  text-align: center;
  animation: slideIn 0.5s ease-in-out; /* Slide-in effect for results */
  opacity: 0; /* Start hidden */
  transition: opacity 0.5s ease-in-out; /* Smooth transition for opacity */
}

.tracking-results-container.visible {
  opacity: 1; /* Show results */
}

.tracking-status-card,
.tracking-details-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;
}

.tracking-status-card h2,
.tracking-details-card h2 {
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.tracking-status-grid,
.tracking-details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.status-item,
.details-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.label {
  color: #000000;
  font-size: 14px;
}

.value {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}

.tracking-timeline {
  margin-top: 40px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;
}

.timeline-item {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0;
}

.timeline-item:last-child {
  border-bottom: none;
}

.timeline-date {
  color: #666;
  font-size: 14px;
}

.timeline-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.timeline-status {
  color: #333;
  font-weight: 500;
}

.timeline-location {
  color: #666;
  font-size: 14px;
}

.tracking-logo {
  width: 200px;
  height: auto;
  filter: brightness(0) invert(0);
}

@media (max-width: 768px) {
  .tracking-page {
    padding: 20px 10px;
  }

  .tracking-page-container {
    padding: 20px;
  }

  .tracking-search-form {
    flex-direction: column;
  }

  .tracking-search-button {
    width: 100%;
  }

  .timeline-item {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .tracking-status-grid,
  .tracking-details-grid {
    grid-template-columns: 1fr;
  }
}

.back-button {
  position: fixed; /* Change to fixed position */
  top: 20px; /* Position from the top */
  left: 20px; /* Position from the left */
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: none;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 2; /* Ensure it stays above other elements */
}

.back-button:hover {
  transform: translateX(-4px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.12);
}

.back-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  transition: transform 0.2s;
}

.back-button:hover .back-icon {
  transform: scale(1.1);
}

.tracking-info-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0;
  border: 1px solid #e0e0e0; /* Light border for the table */
  border-radius: 8px; /* Rounded corners for the table */
  overflow: hidden; /* Ensure rounded corners are visible */
}

.tracking-info-table td {
  padding: 8px; /* Reduced padding in table cells */
  border-bottom: 1px solid #e0e0e0; /* Add bottom border for rows */
  text-align: left;
  transition: background-color 0.3s;
  font-size: 14px; /* Smaller font size for table content */
}

.tracking-info-table td strong {
  color: #333;
}

.tracking-info-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.tracking-info-table tr:hover {
  background-color: #f1f1f1; /* Lighter hover effect for rows */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes backgroundFadeIn {
  0% {
    opacity: 0; /* Start fully transparent */
  }
  100% {
    opacity: 1; /* End fully opaque */
  }
}

@keyframes backgroundImageAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.tracking-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full height for centering */
  position: absolute; /* Position it over the content */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8); /* Light background for contrast */
  z-index: 10; /* Ensure it appears above other elements */
} 